
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, onBeforeRouteUpdate } from 'vue-router';
import NotificationItem from '@/components/NotificationItem/NotificationItem.vue';
import services from '@/services';
import ModulesSkeleton from '@/components/skeletons/ModulesSkeleton.vue';

export default defineComponent({
  components: { NotificationItem, ModulesSkeleton },
  setup() {
    const route = useRoute();
    const modules = ref([]);

    const isLoadingModules = ref(true);

    onBeforeRouteUpdate(async (to, from) => {
      if (to.params.id !== from.params.id) {
        getApplicationFuncionalities(to.params.id);
      }
    });

    async function getApplicationFuncionalities(id: string | string[] | undefined) {
      isLoadingModules.value = true;
      const res = await services.notification.getModuleFuncionalities({ application__slug: id });
      modules.value = res.data.results;
      isLoadingModules.value = false;
    }

    async function onChangeModuleActive(id: number) {
      const res = await services.notification.changeActiveModuleFuncionalities(id);
      if (res.status === 200) getApplicationFuncionalities(route.params.id);
    }

    async function onChangeFunctionality(active: boolean, id: number) {
      await services.notification.changeActiveFunctionality(id, { active });
    }

    onMounted(() => {
      getApplicationFuncionalities(route.params.id);
    });

    return { modules, isLoadingModules, onChangeModuleActive, onChangeFunctionality };
  }
});
