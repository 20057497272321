import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import BaseView from '../views/BaseView.vue';
import HomePage from '../pages/HomePage.vue';
import Auth from '../pages/AuthPage.vue';
import NotFound from '../pages/NotFound.vue';
import BaseContent from '@/components/BaseContent/BaseContent.vue';
import { loginRequired } from '@/utils/loginRequired';

const routes: Array<RouteRecordRaw> = [
  { path: '', component: BaseView },
  {
    path: '/:id',
    component: BaseView,
    children: [
      {
        path: '/:id',
        component: BaseContent,
        children: [
          {
            path: '',
            name: 'Home',
            component: HomePage
          }
        ]
      }
    ]
  },
  {
    path: '/auth/:token/:refreshToken/',
    name: 'auth',
    component: Auth
  },
  { path: '/404', name: 'NotFound', component: NotFound }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  routes
});

router.beforeEach(loginRequired);

export default router;
