
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AvatarIcon',
  props: {
    fullName: { type: String },
    initialLetter: { type: String, default: '' },
    urlAvatar: { type: String }
  }
});
