import { NavigationGuard, RouteLocationNormalized } from 'vue-router';
import services from '@/services';
import { useStorage } from '@vueuse/core';

const storeToken = (to: RouteLocationNormalized) => {
  const token = to.params.token;
  const refreshToken = to.params.refreshToken;

  if (token && refreshToken) {
    localStorage.setItem('token', String(to.params.token));
    localStorage.setItem('refresh_token', String(refreshToken));
  }
};

export const isAuthenticated = async () => {
  const token = useStorage('token', null);

  if (token === null || token === undefined) {
    return false;
  }

  return await services.auth
    .validToken(token.value)
    .then(() => true)
    .catch(() => false);
};

export const loginRequired: NavigationGuard = async (to, from, next) => {
  storeToken(to);
  if (!(await isAuthenticated())) {
    return (window.location.href = process.env.VUE_APP_SSO_URL || '');
  }
  next();
};
