
import VHeader from '@/components/VHeader/VHeader.vue';
import VAppsMenu from '@/components/VAppsMenu/VAppsMenu.vue';
import { defineComponent, ref, onMounted } from 'vue';
import { IApplication } from '@/types';
import services from '@/services';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  components: { VHeader, VAppsMenu },
  setup() {
    const isOpened = ref(true);
    const route = useRoute();
    const router = useRouter();
    const apps = ref<IApplication[]>([]);
    async function getApplications() {
      const { data } = await services.notification.getApplications();
      apps.value = data.results;
    }
    onMounted(async () => {
      await getApplications();

      const firstAppSlug = apps.value[0]?.slug;

      if (!route.params.id) {
        router.push({ path: `/${firstAppSlug}`, replace: true });
      } else if (route.params.id && route.path === '/')
        router.push({ name: 'NotFound', replace: true });
    });

    return { isOpened, apps };
  }
});
