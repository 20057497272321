import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createBlock(_component_el_tooltip, {
    class: "box-item",
    effect: "dark",
    placement: "bottom",
    content: _ctx.fullName
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_avatar, {
        class: "v-avatar-icon",
        src: _ctx.urlAvatar
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.initialLetter.toUpperCase()), 1)
        ]),
        _: 1
      }, 8, ["src"])
    ]),
    _: 1
  }, 8, ["content"]))
}