import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-apps-menu" }
const _hoisted_2 = { class: "sidebar-sticky" }
const _hoisted_3 = { class: "v-apps-menu__list" }
const _hoisted_4 = ["href", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            key: item.id,
            to: item.slug,
            custom: ""
          }, {
            default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
              _createElementVNode("li", {
                class: _normalizeClass(["v-apps-menu__list__item", [
              isActive && 'v-apps-menu__list__item--active',
              isExactActive && 'v-apps-menu__list__item--active'
            ]])
              }, [
                _createElementVNode("a", {
                  href: href,
                  onClick: navigate
                }, _toDisplayString(item.name), 9, _hoisted_4)
              ], 2)
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ])
    ])
  ]))
}