
import { defineComponent, PropType } from 'vue';
import { IApplication } from '@/types';
import { useRoute } from 'vue-router';

export default defineComponent({
  props: {
    list: { type: Array as PropType<IApplication[]> }
  },
  setup() {
    const route = useRoute();
    return {};
  }
});
