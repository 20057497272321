
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    item: { type: Object, required: true }
  },
  emits: ['onChange'],
  setup(props) {
    const itemChecked = ref(props.item.active);

    return { itemChecked };
  }
});
