
import { defineComponent, ref } from 'vue';
import AvatarIcon from '@/components/AvatarIcon/AvatarIcon.vue';

export default defineComponent({
  components: { AvatarIcon },
  setup() {
    const isOpened = ref(false);
    const profileUrl = '';

    return { isOpened, profileUrl };
  }
});
