import { useStorage } from '@vueuse/core';
import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';

const token = useStorage('token', '');

const instance = axios.create({
  baseURL: process.env.VUE_APP_URL_API || '',
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
});

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.headers !== undefined) {
      config.headers.Authorization = `JWT ${token.value}`;
    }

    return config;
  },
  function (error) {
    const err = error.response ? error.response.data : '';
    return Promise.reject(err);
  }
);

export default instance;
