import api from '@/utils/api';

const getApplications = (params = {}) =>
  api.get('/api/notification-center/application/', { params });

const createApplication = (data = {}, params = {}) =>
  api.post('/api/notification-center/application/', data, { params });

const getModuleFuncionalities = (params = {}) =>
  api.get('/api/notification-center/modules-functionalities', { params });

const changeActiveModuleFuncionalities = (id: string | number, params = {}) =>
  api.put(`/api/notification-center/modules-functionalities/${id}/`, { params });

const changeActiveFunctionality = (id: string | number, data = {}) =>
  api.patch(`/api/notification-center/functionality/${id}/`, data);

export default {
  getApplications,
  createApplication,
  getModuleFuncionalities,
  changeActiveModuleFuncionalities,
  changeActiveFunctionality
};
