
import { defineComponent, computed } from 'vue';
import VHeader from '@/components/VHeader/VHeader.vue';

export default defineComponent({
  components: { VHeader },
  setup() {
    const notFoundImg = computed(() => require('@/assets/images/not-found.svg'));

    return { notFoundImg };
  }
});
