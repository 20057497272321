import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-page-home__content" }
const _hoisted_2 = { style: {"padding":"14px"} }
const _hoisted_3 = { style: {"display":"flex","align-items":"center","justify-items":"space-between","margin-top":"16px","height":"26px"} }
const _hoisted_4 = { style: {"padding":"14px"} }
const _hoisted_5 = { style: {"display":"flex","align-items":"center","justify-items":"space-between","margin-top":"16px","height":"26px"} }
const _hoisted_6 = { class: "v-page-home__content" }
const _hoisted_7 = { style: {"padding":"14px"} }
const _hoisted_8 = { style: {"display":"flex","align-items":"center","justify-items":"space-between","margin-top":"16px","height":"26px"} }
const _hoisted_9 = { style: {"padding":"14px"} }
const _hoisted_10 = { style: {"display":"flex","align-items":"center","justify-items":"space-between","margin-top":"16px","height":"26px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_skeleton_item, {
          variant: "h3",
          style: {"width":"50%"}
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_skeleton_item, {
            variant: "text",
            style: {"margin-right":"16px"}
          }),
          _createVNode(_component_el_skeleton_item, {
            variant: "text",
            style: {"width":"30%"}
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_el_skeleton_item, {
          variant: "h3",
          style: {"width":"50%"}
        }),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_skeleton_item, {
            variant: "text",
            style: {"margin-right":"16px"}
          }),
          _createVNode(_component_el_skeleton_item, {
            variant: "text",
            style: {"width":"30%"}
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_el_skeleton_item, {
          variant: "h3",
          style: {"width":"50%"}
        }),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_el_skeleton_item, {
            variant: "text",
            style: {"margin-right":"16px"}
          }),
          _createVNode(_component_el_skeleton_item, {
            variant: "text",
            style: {"width":"30%"}
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_el_skeleton_item, {
          variant: "h3",
          style: {"width":"50%"}
        }),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_el_skeleton_item, {
            variant: "text",
            style: {"margin-right":"16px"}
          }),
          _createVNode(_component_el_skeleton_item, {
            variant: "text",
            style: {"width":"30%"}
          })
        ])
      ])
    ])
  ], 64))
}