
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import services from '@/services';

export default defineComponent({
  setup() {
    const route = useRoute();
    const loading = ref(true);

    onMounted(async () => {
      const token = String(route.params.token);
      const refreshToken = String(route.params.refreshToken);
      writeToken(token, refreshToken);
      validateToken(token);
    });

    function writeToken(token: string, refreshToken: string) {
      localStorage.setItem('token', token);
      localStorage.setItem('refresh_token', refreshToken);
    }

    function validateToken(token: string) {
      services.auth
        .validToken(token)
        .then(() => {
          window.location.href = '/';
        })
        .catch(() => {
          window.location.href = services.auth.authUrl();
        });
    }

    return { loading };
  }
});
