<template>
  <router-view />
</template>
<script>
import { defineComponent, onMounted } from 'vue';
export default defineComponent({
  setup() {
    onMounted(() => {
      document.title = 'Central de Notificações';
    });
  }
});
</script>

<style lang="scss">
@import 'node_modules/bootstrap/scss/bootstrap.scss';
</style>
